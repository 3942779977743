<template>
  <div class="overlay-master">
    <slot></slot>
  </div>
</template>

<script>
import { app } from '@/main'
import { nextTick } from 'vue'

import { getParent, toggleDocumentOverflow } from '@/functions'

export default {
  props: {
    eventBus: { default: null, type: Object },
  },
  methods: {
    toggle(overlayRef) {
      if (overlayRef.isOpen) {
        this.close()
      } else {
        this.open(overlayRef)
      }
    },
    open(overlayRef) {
      if (
        overlayRef.$parent !== undefined &&
        (overlayRef.$parent.hasOverlayChildren === undefined ||
          (overlayRef.$parent.hasOverlayChildren !== undefined &&
            overlayRef.$parent.hasOverlayChildren()))
      ) {
        this.closeAll(overlayRef)
      }

      // setTimeout to defeat the race condition in history.back and history.pushState
      window.setTimeout(() => {
        const owner = this

        app.history.push(() => {
          overlayRef.isOpen = false

          if (app.history.getStackSize() === 0) {
            // only control document overflow on search (not filtering)
            if (getParent(overlayRef.$el, 'search') !== null) {
              if (getParent(overlayRef.$el, 'search--full') === null) {
                toggleDocumentOverflow(false)
              }
            }
          }
          owner.eventBus.emit('child-close', overlayRef)

          nextTick(() => {
            overlayRef.$el.children.forEach(child => {
              if (child.classList.contains('overlay__button')) {
                child.focus()
              }
            })
          })
        }, overlayRef)

        overlayRef.isOpen = true

        if (app.history.getStackSize() === 1) {
          // only control document overflow on search (not filtering)
          if (getParent(overlayRef.$el, 'search') !== null) {
            toggleDocumentOverflow(true)
          }
          owner.eventBus.emit('child-open')
        }
      }, 60)
    },
    close() {
      const scroll = window.scrollY
      app.history.pop()
      // setTimeout to defeat the race condition in history.back and history.pushState
      window.setTimeout(() => {
        window.scrollTo(0, scroll)
      }, 5)
    },
    closeAll(incRef = null) {
      app.history.popAll(incRef)
    },
    closeFirst() {
      if (app.history.getStackSize() === 0) {
        this.eventBus.emit('close-advanced-search')
        return
      }

      this.close()
    },
  },
  created() {
    this.eventBus.on('overlay-toggle', this.toggle)
    this.eventBus.on('overlay-close', this.close)
    this.eventBus.on('overlay-close-all', this.closeAll)
    this.eventBus.on('overlay-close-first', this.closeFirst)
  },
}
</script>

<style lang="scss">
@import 'Overlay.scss';
</style>
