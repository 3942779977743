<script>
import { nextTick } from 'vue'
import { app } from '@/main'

import { debounce } from '@/functions'
import spinner from '@/spinner'

import { LogSearchAds, LogFacebookSearch } from '@/events'

import Overlay from '@shared/Overlay/Overlay.vue'
import OverlayProductType from '@shared/OverlayProductType/OverlayProductType.vue'
import OverlayContainer from '@shared/Overlay/OverlayContainer.vue'
import ClearSearch from '@search/ClearSearch/ClearSearch.vue'

import OverlayEventHandlerMixin from '@shared/Overlay/overlay-event-handler-mixin.vue'
import SearchComponentsMixin from '@search/search-components-mixin.vue'

export default {
  mixins: [SearchComponentsMixin, OverlayEventHandlerMixin],
  props: {
    uiProp: { default: null, type: Object },
    sortItems: { default: null, type: Array },
    numberOfResults: { default: 0, type: Number },
    productTypeNameSingular: { default: '', type: String },
    productTypeNamePlural: { default: '', type: String },
  },
  data() {
    return {
      eventBus: app.mitt,
      sort: '',
      searchRequest: [],
      summaryItems: [],
      ui: {},
      isChildOpen: false,
      adsCount: 0,
    }
  },
  components: {
    OverlayContainer,
    Overlay,
    OverlayProductType,
    ClearSearch,
  },
  methods: {
    getAdsCount: debounce(owner => {
      app.axios
        .post('/Api/Search/Count', owner.getSearchRequestObject())
        .then(resp => {
          owner.adsCount = resp.data
        })
    }, 300),
    searchRequestCallback(options) {
      // If value is undefined - do nothing
      // Example of undefined is conditionType "alle" which should not be shown in searchRequest or summary
      if (options.value === undefined) return

      // if options.selected is set the callback is from Price
      if (
        (options.value.min !== undefined || options.value.max !== undefined) &&
        options.selected === undefined
      ) {
        this.handleSlider(options)
      }

      if (options.selected !== undefined) {
        if (options.searchType === 'sr_Price') {
          this.handlePrice(options)
        } else if (options.searchType === 'sr_Distance') {
          this.handleDistance(options)
        } else {
          // List, Horizontal, ModelList, Price
          this.handleLists(options)
        }
      }

      if (options.searchType === 'sr_TextSearch') {
        this.handleTextSearch(options)
      }

      if (typeof options.countAds !== 'undefined' ? options.countAds : this.countAds) {
        this.getAdsCount(this)
      }
    },
    handleTextSearch(options) {
      const searchRequest = {
        type: options.searchType,
        value: options.value,
      }
      // remove all searchRequests and summaryItems of type
      // remove from searchRequest
      const foundSearchRequests = this.searchRequest.filter(
        x => x.type === searchRequest.type
      )
      if (foundSearchRequests.length > 0) {
        foundSearchRequests.forEach(x => {
          this.searchRequest.splice(this.searchRequest.indexOf(x), 1)
        })
      }

      // remove from summary
      options.summaryOptions.forEach(summaryOption => {
        const foundSummaryItems = this.summaryItems.filter(
          x => x.value === summaryOption.value
        )
        if (foundSummaryItems.length > 0) {
          foundSummaryItems.forEach(x => {
            this.summaryItems.splice(this.summaryItems.indexOf(x), 1)
          })
        }
      })

      if (searchRequest.value === '') return
      // add single new type to searchRequest and summaryItems
      this.searchRequest.push(searchRequest)
      this.summaryItems.push(options.summaryOptions[0])
    },
    handleLists(options) {
      const searchRequest = {
        type: options.searchType,
        value: options.value,
      }

      if (options.searchValue) {
        searchRequest.searchValue = options.searchValue
      }

      // if item is selected add to searchRequest and summary
      if (options.selected) {
        // see if searchRequest already is found
        if (this.searchRequestExists(searchRequest)) return

        // add to searchRequest
        this.searchRequest.push(searchRequest)

        // add to summary
        options.summaryOptions.forEach(summaryOption => {
          // see if summary already is found
          if (this.summaryItemExists(summaryOption)) return

          this.summaryItems.push(summaryOption)
        })
      }
      // if not remove from searchRequest and summary
      else if (!options.selected) {
        // remove from searchRequest
        const foundSearchRequests = this.filterSearchRequest(searchRequest)
        if (foundSearchRequests.length > 0) {
          foundSearchRequests.forEach(x => {
            this.searchRequest.splice(this.searchRequest.indexOf(x), 1)
          })
        }

        // remove from summary
        options.summaryOptions.forEach(summaryOption => {
          const foundSummaryItems = this.filterSummaryItems(summaryOption)
          if (foundSummaryItems.length > 0) {
            foundSummaryItems.forEach(x => {
              this.summaryItems.splice(this.summaryItems.indexOf(x), 1)
            })
          }
        })
      }
    },
    handlePrice(options) {
      const searchRequest = {
        type: options.searchType,
        value: options.value,
      }

      // remove from searchRequest
      const foundSearchRequests = this.searchRequest.filter(
        x => x.type === searchRequest.type
      )
      if (foundSearchRequests.length > 0) {
        foundSearchRequests.forEach(x => {
          this.searchRequest.splice(this.searchRequest.indexOf(x), 1)
        })
      }

      const shouldReport =
        options.selected && options.value.paymentTypeId !== undefined

      // remove from summary
      // and add if shouldReport
      options.summaryOptions.forEach(summaryOption => {
        const foundSummaryItems = this.summaryItems.filter(
          x => x.type === options.searchType
        )
        if (foundSummaryItems.length > 0) {
          foundSummaryItems.forEach(x => {
            this.summaryItems.splice(this.summaryItems.indexOf(x), 1)
          })
        }

        if (shouldReport) {
          // add to summaryOptions
          this.summaryItems.push(summaryOption)
        }
      })

      // Add to searchRequest if should report
      if (shouldReport) {
        // add to searchRequest
        this.searchRequest.push(searchRequest)
      }
    },
    handleDistance(options) {
      const searchRequest = {
        type: options.searchType,
        value: options.value,
      }

      // remove from searchRequest
      const foundSearchRequests = this.searchRequest.filter(
        x => x.type === searchRequest.type
      )
      if (foundSearchRequests.length > 0) {
        foundSearchRequests.forEach(x => {
          this.searchRequest.splice(this.searchRequest.indexOf(x), 1)
        })
      }

      const shouldReport = options.selected

      // remove from summary
      // and add if shouldReport
      options.summaryOptions.forEach(summaryOption => {
        const foundSummaryItems = this.summaryItems.filter(
          x => x.type === options.searchType
        )

        if (foundSummaryItems.length > 0) {
          foundSummaryItems.forEach(x => {
            this.summaryItems.splice(this.summaryItems.indexOf(x), 1)
          })
        }

        if (shouldReport) {
          // add to summaryOptions
          this.summaryItems.push(summaryOption)
        }
      })

      // Add to searchRequest if should report
      if (shouldReport) {
        // add to searchRequest
        this.searchRequest.push(searchRequest)
      }
    },
    handleSlider(options) {
      const searchRequest = {
        type: options.searchType,
        value: options.value,
      }

      // remove from searchRequest
      const foundSearchRequests = this.searchRequest.filter(
        x => x.type === searchRequest.type
      )
      if (foundSearchRequests.length > 0) {
        foundSearchRequests.forEach(x => {
          this.searchRequest.splice(this.searchRequest.indexOf(x), 1)
        })
      }

      // remove from summary
      const foundSummaryItems = this.summaryItems.filter(
        x =>
          x.type === options.searchType + '_min' ||
          x.type === options.searchType + '_max'
      )
      if (foundSummaryItems.length > 0) {
        foundSummaryItems.forEach(x => {
          this.summaryItems.splice(this.summaryItems.indexOf(x), 1)
        })
      }

      // and add if shouldReport
      options.summaryOptions.forEach(summaryOption => {
        // add to summaryOptions
        this.summaryItems.push(summaryOption)
      })

      const shouldReport =
        searchRequest.value.min !== null || searchRequest.value.max
      // Add to searchRequest if should report
      if (shouldReport) {
        // add to searchRequest
        this.searchRequest.push(searchRequest.value)
      }
    },
    searchRequestExists(searchRequest) {
      return this.filterSearchRequest(searchRequest).length > 0
    },
    filterSearchRequest(searchRequest) {
      return this.searchRequest.filter(
        x => x.type === searchRequest.type && x.value === searchRequest.value
      )
    },
    summaryItemExists(summaryItem) {
      return this.filterSummaryItems(summaryItem).length > 0
    },
    filterSummaryItems(summaryItem) {
      return this.summaryItems.filter(x => x.name === summaryItem.name)
    },
    setSort(sort) {
      if (this.sort === sort) return
      this.sort = sort
    },
    getSearchRequestObject() {
      const searchRequestObj = {}
      searchRequestObj.searchRequest = this.prepareSearchRequest()
      if (this.sort !== '') {
        searchRequestObj.sort = this.sort
      }

      return searchRequestObj
    },
    handleCreate() {
      spinner.start()

      LogSearchAds()
      LogFacebookSearch()

      app.axios
        .post('/Api/Search/Create', this.getSearchRequestObject())
        .then(resp => {
          window.location.href = resp.data.toString()
        })
    },
    prepareSearchRequest() {
      const copySearchRequest = JSON.parse(JSON.stringify(this.searchRequest)) // figure out a better / more correct way to copy a data element (Vue.util.extend is a shallow copy and won't help here)

      copySearchRequest.forEach(item => {
        const requestItem = item
        // Ensure properties with searchValue is being set correctly for instance:
        //
        // {"name":"Aircondition","value":"Aircondition","searchValue":true}
        // Aircondition needs to be {"type":"Aircondition","value":"true"}
        //
        // Normal search properties for instance
        // {"name":"Forhjulstræk","value":1}
        // WheelDriveType will be {"type":"WheelDriveTypeId","value":1}
        if (requestItem.searchValue !== undefined) {
          requestItem.type = requestItem.value
          requestItem.value = requestItem.searchValue

          requestItem.searchValue = undefined
        }
      })

      return copySearchRequest
    },
    clearSearch() {
      this.summaryItems.forEach(summaryItem => {
        nextTick(() => {
          summaryItem.clearSelf()
        })
      })
    },
  },
  created() {
    this.eventBus.on('search-request-callback', this.searchRequestCallback)

    this.eventBus.on('create-search', this.handleCreate)
    this.eventBus.on('child-open', () => {
      this.isChildOpen = true
    })
    this.eventBus.on('child-close', () => {
      this.isChildOpen = false
    })
    this.eventBus.on('clear-search', this.clearSearch)

    this.ui = this.uiProp

    this.adsCount = this.numberOfResults
  },
}
</script>
