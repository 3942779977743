<script>
import { app } from '@/main'

import { getParent } from '@/functions'

export default {
  methods: {
    handleDocumentClick(event) {
      if (!app.closable) return

      if (getParent(event.target, 'overlay-master') !== null) {
        return
      }
      this.eventBus.emit('close-advanced-search')
      this.eventBus.emit('overlay-close-all')
    },
    handleKeyUp(event) {
      if (event.keyCode !== 27) return

      this.eventBus.emit('overlay-close-first')
    },
  },
  created() {
    window.addEventListener('click', this.handleDocumentClick)
    window.addEventListener('keyup', this.handleKeyUp)
  },
}
</script>
